import styled from "styled-components";

export const H1 = styled.h1`
  font-weight: bold;
  font-size: 100px;
  color: #73667d;
`;

export const H2 = styled.h2`
  font-weight: bold;
  font-size: 40px;
  color: #73667d;
`;

export const H3 = styled.h3`
  font-weight: bold;
  font-size: 30px;
  color: #73667d;
`;

export const H4 = styled.h4`
  font-weight: bold;
  font-size: 25px;
  color: #73667d;
`;
export const H1C = styled.h1`
  font-weight: bold;
  font-size: 100px;
  color: #a298ab;
`;

export const H2C = styled.h2`
  font-weight: bold;
  font-size: 40px;
  color: #a298ab;
`;

export const H3C = styled.h3`
  font-weight: bold;
  font-size: 30px;
  color: #a298ab;
`;

export const H4C = styled.h4`
  font-weight: bold;
  font-size: 25px;
  color: #a298ab;
`;
export const H1S = styled.h1`
  font-weight: bold;
  font-size: 100px;
  color: #8abad3;
`;

export const H2S = styled.h2`
  font-weight: bold;
  font-size: 40px;
  color: #8abad3;
`;

export const H3S = styled.h3`
  font-weight: bold;
  font-size: 30px;
  color: #8abad3;
`;

export const H4S = styled.h4`
  font-weight: bold;
  font-size: 25px;
  color: #8abad3;
`;

export const Logo = styled.h5`
  font-family: Nineteenth;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 44px;
`;

export const Body11 = styled.p`
  font-weight: bold;
  font-size: 20px;
  color: #73667d;
`;

export const Body12 = styled.p`
  font-weight: 600;
  font-size: 20px;
  color: #73667d;
`;

export const Body13 = styled.p`
  font-weight: normal;
  font-size: 20px;
  color: #73667d;
`;

export const Body21 = styled.p`
  font-weight: bold;
  font-size: 16px;
  color: #73667d;
`;

export const Body22 = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: #73667d;
`;

export const Body23 = styled.p`
  font-weight: normal;
  font-size: 16px;
  color: #73667d;
`;

export const Body31 = styled.p`
  font-weight: bold;
  font-size: 14px;
  color: #73667d;
`;

export const Body32 = styled.p`
  font-weight: 600;
  font-size: 14px;
  color: #73667d;
`;

export const Body33 = styled.p`
  font-weight: normal;
  font-size: 14px;
  color: #73667d;
`;

export const Body41 = styled.p`
  font-weight: bold;
  font-size: 12px;
  color: #73667d;
`;

export const Body42 = styled.p`
  font-weight: 600;
  font-size: 12px;
  color: #73667d;
`;

export const Body43 = styled.p`
  font-weight: normal;
  font-size: 12px;
  color: #73667d;
`;

export const Caption = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: #73667d;
  text-transform: uppercase;
`;
export const Body11C = styled.p`
  font-weight: bold;
  font-size: 20px;
  color: #a298ab;
`;

export const Body12C = styled.p`
  font-weight: 600;
  font-size: 20px;
  color: #a298ab;
`;

export const Body13C = styled.p`
  font-weight: normal;
  font-size: 20px;
  color: #a298ab;
`;

export const Body21C = styled.p`
  font-weight: bold;
  font-size: 16px;
  color: #73667d;
`;

export const Body22C = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: #a298ab;
`;

export const Body23C = styled.p`
  font-weight: normal;
  font-size: 16px;
  color: #a298ab;
`;

export const Body31C = styled.p`
  font-weight: bold;
  font-size: 14px;
  color: #a298ab;
`;

export const Body32C = styled.p`
  font-weight: 600;
  font-size: 14px;
  color: #a298ab;
`;

export const Body33C = styled.p`
  font-weight: normal;
  font-size: 14px;
  color: #a298ab;
`;

export const Body41C = styled.p`
  font-weight: bold;
  font-size: 12px;
  color: #a298ab;
`;

export const Body42C = styled.p`
  font-weight: 600;
  font-size: 12px;
  color: #a298ab;
`;

export const Body43C = styled.p`
  font-weight: normal;
  font-size: 12px;
  color: #a298ab;
`;

export const CaptionC = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: #a298ab;
  text-transform: uppercase;
`;
export const Body11S = styled.p`
  font-weight: bold;
  font-size: 20px;
  color: #8abad3;
`;

export const Body12S = styled.p`
  font-weight: 600;
  font-size: 20px;
  color: #8abad3;
`;

export const Body13S = styled.p`
  font-weight: normal;
  font-size: 20px;
  color: #8abad3;
`;

export const Body21S = styled.p`
  font-weight: bold;
  font-size: 16px;
  color: #73667d;
`;

export const Body22S = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: #8abad3;
`;

export const Body23S = styled.p`
  font-weight: normal;
  font-size: 16px;
  color: #8abad3;
`;

export const Body31S = styled.p`
  font-weight: bold;
  font-size: 14px;
  color: #8abad3;
`;

export const Body32S = styled.p`
  font-weight: 600;
  font-size: 14px;
  color: #8abad3;
`;

export const Body33S = styled.p`
  font-weight: normal;
  font-size: 14px;
  color: #8abad3;
`;

export const Body41S = styled.p`
  font-weight: bold;
  font-size: 12px;
  color: #8abad3;
`;

export const Body42S = styled.p`
  font-weight: 600;
  font-size: 12px;
  color: #8abad3;
`;

export const Body43S = styled.p`
  font-weight: normal;
  font-size: 12px;
  color: #8abad3;
`;

export const CaptionS = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: #a298ab;
  text-transform: uppercase;
`;
